import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { RichText, Date } from 'prismic-reactjs'
import { Text } from '../components/slices'
import { linkResolver } from '../utils/linkResolver'
import Gallery from "react-photo-gallery"

const FeaturedPhotoDisplay = () => {

  const data = useStaticQuery(graphql`
  query FeaturedPhotosQuery {
    prismicPhotogroup(uid: {eq: "featured"}) {
      data {
        photos {
          photo {
            alt
            src: url
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  }
  `)
  const featuredphotos = data.prismicPhotogroup.data.photos

  function translatedimensions(ref1, ref2){
    var num = "1"
    if (ref1 > ref2){
      num = "4"
    }else{
      num = "3"
    }
    return{
      num
    }
  }

  const galleryphotos = []
  featuredphotos.forEach((item, i) => galleryphotos.push({
    src:item.photo.src,
    height:item.photo.dimensions.height,
    width:item.photo.dimensions.width
  }))

  const featuredphotoid = 0
  return(
    <div className="mb-5 featured__photos__gallery">
      <Gallery photos={galleryphotos} />
    </div>
  )
}

const PhotoSetTeaserTitle = (photoset) => {
  const defaultPhotosetTitle = ''
  return(
    // photoset.photoset_title.raw.length !== 0 ? photoset.photoset_title.text : defaultPhotosetTitle
    RichText.asText(photoset.node.data.title)
  )
}

const PhotoSetTeaser = ({ photoset, id }) => {
  return (
    <div className="work__teaser col-12 col-lg-4 mb-5">
      <Link to={photoset.node.url}>
          <img src={photoset.node.data.featured_image.url} alt=""
          className="img-fluid" />
        <div>
          <span className="display__title">{PhotoSetTeaserTitle(photoset)}</span>
        </div>
    </Link>
    </div>
  )
}

export default ({ photosets }) => {

  return (
    <div className="media__body__center">
      <FeaturedPhotoDisplay />
      <div className="pt-5">
        <h3 className="display__title">Photo Essays & Collections</h3>
      </div>
      <div className="teaser__grid row">
        {photosets.map((photoset) => (
          <PhotoSetTeaser photoset={photoset} key={photoset.node.id} />
        ))}
      </div>
    </div>
  )
}
