import React from 'react'
import { Link } from 'gatsby'
import { RichText, Date } from 'prismic-reactjs'
import { Text } from '../components/slices'
import { linkResolver } from '../utils/linkResolver'

const VideoTeaserTitle = (videolist) => {
  const defaultVideoTitle = ''
  return(
    RichText.asText(videolist.node.data.title)
  )
}

const VideoTeaser = ({ videolist, id }) => {
  return (
    <div className="work__teaser col-12 col-lg-6 mb-5">
      <Link to={videolist.node.url}>
        <img
          src={videolist.node.data.thumbnail.url}
          alt={videolist.node.data.title}
          className="img-fluid"
        />
        <div className="pt-2">
          <span className="display__title">{VideoTeaserTitle(videolist)}</span>
        </div>
    </Link>
    </div>
  )
}

export default ({ videolist }) => {
  return (
      <div className="media__body__center">
        <div className="work__grid row">
          {videolist.map((videolist) => (
            <VideoTeaser videolist={videolist} key={videolist.node.id} />
          ))}
        </div>
      </div>
  )
}
