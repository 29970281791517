import React from 'react'
import { Link } from 'gatsby'
import { RichText, Date } from 'prismic-reactjs'
import { Text } from '../components/slices'
import { linkResolver } from '../utils/linkResolver'

const TeaserTitle = (list) => {
  const defaultTitle = ''
  return(
    RichText.asText(list.node.data.title)
  )
}

const Teaser = ({ list, id }) => {
  return (
    <div className="work__teaser col-12 col-lg-6 mb-5">
      <Link to={list.node.url}>
        <img
          src={list.node.data.featured_image.url}
          alt={list.node.data.title}
          className="img-fluid"
        />
        <div className="pt-3">
          <span className="display__title">{TeaserTitle(list)}</span>
        </div>
    </Link>
    </div>
  )
}

export default ({ list }) => {
  return (
    <div className="media__body__center">
      <div className="work__grid row">
        {list.map((list) => (
          <Teaser list={list} key={list.node.id} />
        ))}
      </div>
    </div>
  )
}
