import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import SEO from '../components/SEO'
import Layout from '../components/layouts'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'
import PhotoSets from '../components/PhotoSets'
import VideoGrid from '../components/videogrid'
import WritingList from '../components/WritingList'
import DesignList from '../components/DesignList'
import CodeList from '../components/CodeList'
import AudioList from '../components/AudioList'

const Page = ({data}) => {
  if (!data) return null
  const document = data.allPrismicDisciplineType.edges[0].node
  const photosets = data.allPrismicPhotogroup.edges
  const videolist = data.allPrismicVideoItem.edges
  const writinglist = data.allPrismicWritingItem.edges
  const designlist = data.allPrismicDesignSet.edges
  const audiolist = data.allPrismicAudioItem.edges
  const codelist = data.allPrismicCodeItem.edges
    return (
      <Layout>
      <SEO title={RichText.asText(document.data.title)} />
        <div className="mt-5">
          <div className="page__content mb-5">
            <div className="copy__body__center">
              <h1 className="display__title">{RichText.asText(document.data.title)}</h1>
              <div className="description">
                <RichText render={document.data.description} linkResolver={linkResolver} htmlSerializer={htmlSerializer} />
              </div>
            </div>
          </div>
          <div className="page__content">
              {document.uid === 'photography' && <PhotoSets photosets={photosets} />}
              {document.uid === 'video' && <VideoGrid videolist={videolist} />}
              {document.uid === 'writing' && <WritingList list={writinglist} />}
              {document.uid === 'design' && <DesignList list={designlist} />}
              {document.uid === 'code' && <CodeList list={codelist} />}
              {document.uid === 'audio' && <AudioList list={audiolist} />}
          </div>
        </div>
      </Layout>
    )
  }

// Query for the Blog Post content in Prismic
export const query = graphql`
query DisciplineQuery($uid: String) {
  allPrismicDisciplineType(filter: {uid: {eq: $uid}}) {
    edges {
      node {
        data {
          description {
            type
            text
            spans {
              start
              end
              type
            }
          }
          title {
            type
            text
          }
        }
        uid
      }
    }
  }

  allPrismicPhotogroup(filter: {uid: {ne: "featured"}}, sort: {fields: data___order}) {
    edges {
      node {
        url
        uid
        type
        data {
          description {
            type
            text
          }
          featured_image {
            url
            alt
          }
          title {
            type
            text
          }
        }
        id
      }
    }
  }

  allPrismicVideoItem(sort: {order: ASC, fields: data___order}) {
    edges {
      node {
        url
        uid
        type
        data {
          thumbnail {
            url
          }
          title {
            type
            text
          }
          description {
            type
            text
          }
          skills {
            type
            text
          }
        }
      }
    }
  }

  allPrismicWritingItem(sort: {order: DESC, fields: first_publication_date}) {
    edges {
      node {
        url
        uid
        data {
          title {
            type
            text
          }
          thumbnail {
            url
          }
          description {
            type
            text
          }
          outlet {
            type
            text
          }
          link {
            link_type
            url
          }
        }
        id
      }
    }
  }

  allPrismicDesignSet {
    edges {
      node {
        url
        uid
        data {
          description {
            type
            text
          }
          featured_image {
            url
          }
          title {
            type
            text
          }
        }
        type
        id
      }
    }
  }
  allPrismicCodeItem {
    edges {
      node {
        url
        uid
        type
        id
        data {
          featured_image {
            url
          }
          description {
            type
            text
          }
          title {
            type
            text
          }
        }
      }
    }
  }
  allPrismicAudioItem {
    edges {
      node {
        url
        uid
        type
        id
        data {
          title {
            type
            text
          }
          thumbnail {
            url
          }
          description {
            type
            text
          }
        }
      }
    }
  }

}
`

export default Page
