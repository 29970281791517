import React from 'react'
import { Link } from 'gatsby'
import { RichText, Date } from 'prismic-reactjs'
import { Text } from '../components/slices'
import { linkResolver } from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/fontawesome'

const TeaserTitle = (list) => {
  const defaultTitle = ''
  return(
    RichText.asText(list.node.data.title)
  )
}

const Teaser = ({ list, id }) => {
  const item = list.node
  return (
      <div className="work__teaser mb-5">
        <div className="mb-3">
          <img
            src={item.data.thumbnail.url}
            alt={item.data.title}
            className="img-fluid"
          />
          </div>
          <div>
            <span className="eyebrow">{RichText.asText(item.data.outlet)}</span>
          </div>
          <div>
            <span
              className="display__title">
              {TeaserTitle(list)}
            </span>
          </div>
          <div className="description mb-3">
            {RichText.asText(item.data.description)}
          </div>
          <div>
            <a href={item.data.link.url}>View on {RichText.asText(item.data.outlet)} <FontAwesomeIcon icon={'long-arrow-alt-right'} />
            </a>
          </div>
      </div>
  )
}

export default ({ list }) => {
  return (
    <div className="copy__body__center">
      <div className="work__grid">
        {list.map((list) => (
          <Teaser list={list} key={list.node.id} />
        ))}
      </div>
    </div>
  )
}
